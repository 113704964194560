import React from 'react';
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";
import { RouterLink } from "./components/cmtsNavigation.js";
import { ReactComponent as CMTSLogo } from "./media/cmts_logo.svg";
import { ReactComponent as CMTSNETLogo } from "./media/cmts_net_logo.svg";

function CMTSAbout() {
    return (
        <>
            <div className="cell grid-x button-array">
                <RouterLink activeOnlyWhenExact to="/about" label="About CMTS" />
                <RouterLink to="/about/tutorial" label="Tutorial" />
                <RouterLink to="/about/tos" label="ToS" />
                <RouterLink to="/about/privacy" label="Privacy" />
            </div>
            <div className="cell grid-x grid-container" style={{ width: "100%" }}>
                <div className="cell small-12 readable-content">
                    <Switch>
                        <Route exact path="/about">
                            <GeneralAbout />
                        </Route>
                        <Route exact path="/about/tutorial">
                            <Tutorial />
                        </Route>
                        <Route exact path="/about/tos">
                            <ToS />
                        </Route>
                        <Route exact path="/about/privacy">
                            <Privacy />
                        </Route>
                    </Switch>
                </div>

            </div>
        </>
    )
}

function ButtonArrayLink({ label, to, activeOnlyWhenExact, onClick }){
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <Link to={to} onClick={onClick}>
            <li className={match ? "active" : ""}>
                {label}
            </li>
        </Link>
    )
}

function GeneralAbout() {
    return (<>
        <div className="reader-container">
            <div className="grid-y grid-margin-x align-center-middle">
                <CMTSLogo className="small-3" />
                <CMTSNETLogo className="small-11 medium-6" style={{ "paddingBottom": "1em" }} />
                <p style={{ fontSize: ".9em" }}>version {process.env.REACT_APP_VERSION}</p>
            </div>

            <p>
                comet<strong>spectrum</strong> is a Super Mario 64 <Link className="reference" to="/about/tutorial#colorcode">colorcode</Link> database, developed by <a href="https://glitchypsi.xyz">GlitchyPSI</a>, for Project Comet.<br /><br />
            Started out as a Discord bot, it allows people to submit their color codes for public viewing and personal storage. The necessity came from people asking where others could get people's color codes, but not having a proper way to share them other than any random service, or simply losing color codes when switching computers or the like.<br /><br />
            The goal of comet<strong>spectrum</strong> is to centralize the place where they can be obtained, and potentially find more color codes to use and, with them, perhaps some new friends to put in their videos.
        </p>
            <h1>Credits</h1><br />
            <h4>Programmer, designer, concept</h4>
            <p>
                GlitchyPSI<br />
            </p>
            <h4>Project Comet Staff</h4>
            <p>
                Captainswag101<br />
                <a href="https://bsky.app/profile/xenova.xyz">Xenovɑ</a><br />
            </p>
            <h4>✨Patrons✨</h4>
            <p>
                PonderMan<br />
                Waro<br />
                MrGameAndSketch<br />
                Want your name here? Support me on <a href="https://patreon.com/GlitchyPSI">Patreon</a>.
            </p>
            <h4>Third-party technologies</h4>
            <p>
                <a href="https://josh.evie.dev">JOSH</a> &amp; <a href="https://enmap.evie.dev">Enmap</a><br />
                <a href="https://reactjs.org">React</a><br />
            (There's more. It's just too many.)<br />
            </p>
            <h4>CMTS v1.0 testers</h4>
            <p>
                TheBestNSMB<br />
            FireGlitch64<br />
            NovaQ<br />
            Phe9nix<br />
            JDJG<br />
            Mario 2.0 Productions<br />
            </p>
            <h4>Special thanks</h4>
            <p>
            Yoshielectron (James S)<br />
            FM54321<br />
            The people that tested CMTS in SMG4's server<br />
            My <a href="https://youtube.com/GlitchyPSI">subscribers</a><br />
            My <a href="https://bsky.app/profile/glitchypsi.xyz">followers</a><br />
                <br />
                <br />
                <br />
                <br />
                <span style={{ opacity: "0.01" }}>the ghost of the loved one</span>
            </p>
        </div>
    </>
    )

}

function ToS() {
    return (
        <>
            <div className="cell">
                <div className="reader-container">
                    <h1>Introduction</h1><br />
                    <p>
                        Please read these terms and conditions of service (henceforth referred to as "Terms") carefully before using cometspectrum, and related projects such as cometspectrum-net (collectively referred to as the "Service") provided by Project Comet ("us", 'we", "our").
                    <br /> In addition to this, also do read the Privacy Policy <Link to="/about/privacy">here</Link>.<br />
                    These terms may change at any time and without prior notice, which is why it is recommended to check the Terms every now and often. If you do not agree with an update to the Terms, you may stop using the Service.<br />
                    By using or accessing any portion of the Service, you agree that you are (i) at the very least 13 years old and (ii) the majority of age for digital consent in your country.
                    </p>
                    <h1>1. Description of Service</h1>
                    <p>cometspectrum is a service designed to store specific Gameshark codes or character model data that change the appearance of the player character in a specific, emulated game.<br /><br />
                    </p>
                    <h1>2. Code of Conduct</h1>
                    <p>You may not use the Service to:</p>
                    <ul>
                        <li>post content that might be objectionable according to the rules in the Project Comet Discord server, the Code of Conduct or violates parts of these Terms,</li>
                        <li>post content with the intention of harm, defame and/or harass anyone,</li>
                        <li>post malicious content that might damage the devices of those accessing the Service or the Service itself,</li>
                        <li>violate the intellectual or other kinds of rights of an individual or organization.</li>
                    </ul><br /><br />
                    <p>
                        The list of restrictions above is not an explicit list, and we may take action on a case-by-case basis.
                    </p>
                    <h1>3. Termination of Service</h1><br />
                    <p>
                        You can willfully terminate your use of the Service and, as a consequence, erase most data of your submissions from the servers by contacting us through any possible medium.<br />
                    Your access to the Service may be terminated or limited by us with no obligation of providing further notice, including but not limited to the violation of the Code of Conduct.<br />
                    The Content submitted to the Service by you may or may not be available for personal backup upon account termination and, while whenever possible said backups may be made available to you, this may not always be the case.<br />
                    </p>
                    <h1>4. Indemnity</h1>
                    <p>
                        You agree to defend, indemnify and hold harmless Project Comet, its parent corporation, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of these Terms of Service; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that one of your User Submissions caused damage to a third party.<br /><br />
                    This defense and indemnification obligation will survive these Terms of Service and your use of the Service.
                    </p>
                    <h1>5. User submissions and intellectual property rights</h1>
                    <p>
                        You retain the rights to the user-submitted content that is provided to the Service for public display (henceforth referred to as Content).<br />
                    Unless explicitly stated, no opinion, submission, or advice is endorsed by Project Comet and all liability is disclaimed from such.<br />
                    The submission of content that infringes intellectual property rights is not permitted. If you wish to request a takedown of specific Content, you can contact us through <a href="mailto:dmca@glitchypsi.xyz">dmca@glitchypsi.xyz</a>.
                    <br />We reserve the right to remove or limit the visibility of some Content with or without prior warning to the submitter of said Content.<br />
                    </p>
                    <h1>4. Disclaimer of warranty</h1><br />
                    <p>
                        THE WEBSITE AND ALL SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NEITHER THESE TERMS NOR ANY OTHER DOCUMENT INCORPORATED BY REFERENCE HEREIN NOR ANY DOCUMENTATION FURNISHED UNDER ANY OF THEM ARE INTENDED TO EXPRESS OR IMPLY ANY WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. WITHOUT LIMITATION, WE MAKE NO WARRANTY THAT THE WEBSITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS, THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, THAT THE QUALITY OF ANY SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES WILL MEET YOUR EXPECTATIONS; AND THAT ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR OUR SERVICES OR THROUGH OR FROM THE WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.<br />
                    </p>
                    <h1>5. Limited liability</h1><br />
                    <p>
                        IN NO EVENT SHALL PROJECT COMET, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br />
                    WHILE THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION, WHERE CONDITIONS, WARRANTIES OR OTHER RIGHTS ARE IMPLIED IN THIS AGREEMENT OR OTHERWISE CONFERRED BY LAW, AND IT IS NOT LAWFUL OR POSSIBLE TO EXCLUDE THEM, THEN THOSE CONDITIONS, WARRANTIES OR OTHER RIGHTS WILL (BUT ONLY TO THE EXTENT REQUIRED BY LAW) APPLY TO THIS AGREEMENT.<br />
                    AT THE OPTION OF PROJECT COMET, OUR LIABILITY FOR BREACH OF IMPLIED CONDITIONS OR WARRANTIES IS LIMITED, TO THE EXTENT PERMITTED BY LAW TO, IF THE BREACH RELATES TO GOODS, (I) THE REPLACEMENT OF THE GOODS OR THE SUPPLY OF EQUIVALENT GOODS, (II) THE REPAIR OF THE GOODS, (III) THE PAYMENT OF THE COST OF REPLACING THE GOODS OR OF ACQUIRING EQUIVALENT GOODS, OR (IV) THE PAYMENT OF HAVING THE GOODS REPAIRED AND, IF THE BREACH RELATES TO SERVICES, (I) THE SUPPLY OF THE SERVICES AGAIN; OR (II) THE PAYMENT OF THE COST OF HAVING THE SERVICES SUPPLIED AGAIN.<br />
                    YOU SPECIFICALLY ACKNOWLEDGE THAT PROJECT COMET SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.<br />
                    The Service is controlled by Project Comet under the jurisdiction of the Dominican Republic. We make no representations that the Service is appropriate or available for use in other locations. Those who access or use the Service from other jurisdictions do so at their own volition and are responsible for compliance with local law.<br />
                    </p>
                </div>
            </div>
        </>
    )
}

function Privacy() {
    return (
        <>
            <div className="cell">
                <div className="reader-container">
                    <h1>Changes to the Policy</h1>
                    <p>
                        The Privacy Policy may change at any point in time to accomodate for the data that is collected from the users. It is advisable to check the Privacy Policy for any possible updates every now and often.<br />
                        Last update: 6 July, 2021.
                    </p>
                    <h1>What data is collected by us</h1>
                    <h3>by using cometspectrum-net (website, API) in general</h3>
                    <p>We might log the following:
                    </p><ul>
                        <li>IP address (to rate-limit people that were to abuse the Service)</li><br />
                        <p>Additionally, the website uses Google Analytics to track which users use the site at determined hours. This may leave Cookies. The Analytics data tracking can be disabled by sending a Do Not Track request in your browser, or using a tracker blocker.<br />
                        </p></ul><br />
                    <h3>by using cometspectrum (bot)</h3>
                    <p>We will keep the following data about the users of the Discord bot:<br />
                    </p><ul>
                        <li>ID of the Discord user (for storage purposes)</li>
                        <li>Possible assigned CMTS Alias</li>
                        <li>Colorcodes submitted and relevant information (such as names)</li>
                        <li>Logs about bot command usage (to determine possible failures in the Service)</li>
                    </ul><br />
                    <h1>Cookies</h1><br />
                    <p>Yes, we do use cookies. The current usage of Cookies is only for tracking purposes (See: Google Analytics), but the usage might be expanded in a future.<br />
                    Cookies are currently non-crucial. In a future they will become crucial, and you will need to accept cookies to be able to use cometspectrum-net.<br />
                    </p><h1>Data Security &amp; Disclosure</h1>
                    <p>All data submitted to comet<strong>spectrum</strong> is saved safely and only transferred around to requesters (such as you) through encrypted channels such as secure HTTP.<br />
                        Unless changed by you, color codes by default are public. You may toggle the obtainment permission for a particular color code by using the <code>cs!ccpermission</code> command.<br />
                        All customizations (except Character Mods) are added to the public statistics page for viewing and usage by anyone. The data used in statistics is transformed in such a way that no identificative data is stored, making it anonymous. However, if you were to wish for your customization to not be counted in Statistics, you may remove your customization from your uploaded ones.<br/>
                        Past regular usage of the service, no data is disclosed to any unknown third party without your consent. We value your privacy and for this very reason we will keep your personal information secure.<br />
                    </p>
                    <h1>Children</h1>
                    <p>In compliance with the Children's Online Privacy Protection Act (COPPA) and the European General Data Protection Regulation (GDPR), users under 13 years and or the majority of age for digital consent for the respective country cannot use this Service without parental permission.<br />
                    If we figure out a user is not of legal age to use this service without parental permission, we will limit the visibility of the submitted information before deleting it if no permission has been granted in a timespan of three (3) days.<br />
                    </p><h1>Right to Erasure</h1>
                    <p>For now, deletion of all data for yourself is manual.<br />
                    You may request the deletion of your data by contacting <a href="mailto:deletion@glitchypsi.xyz">deletion@glitchypsi.xyz</a> with the subject "CMTS Account Data Deletion", including your Discord ID and the reason for deletion (if any), or sending the same information through Discord DMs to my user (which is available at Project Comet).
                </p>
                </div>
            </div>
        </>
    )
}

function Tutorial() {
    return (
        <>
            <div className="cell">
                <div className="reader-container">
                    <p>
                        Before using the bot let's go over some basics. Feel free to skip these if you already are a machinimist and are experienced with things like Color Codes and <strong>CometSPARK. (important!)</strong><br /><br />
                    </p>
                    <h1>List of contents</h1>
                    <ul>
                        <li><a href="#colorcode">What are color codes?</a></li>
                        <li><a href="#create-colorcodes">Where can I create a color code?</a></li>
                        <li><a href="#spark">What is SPARK?</a></li>
                        <li><a href="#get-web">How do I get somebody else's color code?</a></li>
                        <li><a href="#save-web">How do I save a color code to the database?</a></li>
                        <li><a href="#save-more">How do I save more color codes to the database?</a></li>
                        <li><a href="#slots">How do I know how many color codes can I save?</a></li>
                        <li><a href="#get-slots">How do I get more slots?</a></li>
                    </ul>
                    <h1 id="colorcode">1. What are "Colorcodes"?</h1>
                    <p>
                        A Color Code is a gameshark that changes Mario's colors in SM64. It does not work with mods that are not compatible, such as <strong>X³</strong>, which only uses textures.<br />
                    Color codes are <strong>NOT</strong> pictures of a recolored Mario or just a single hexadecimal code, like <strong>#366FFF</strong>.

                    <br />
                    comet<strong>spectrum</strong> only saves color codes.
                    <br />
                    </p>
                    <h2 id="create-colorcodes">1.1. Where can I create a color code?</h2>
                    <p>
                        You can create a color code through several ways:
                    </p>
                    <ul>
                        <li>Using any version of <a href="https://github.com/projectcomet64/M64MM/releases/latest">Mario 64 Movie Maker (M64MM)</a></li>
                        <li>The <a href="https://github.com/projectcomet64/SPARK/releases/latest">SPARK Editor</a>, which is <em>also</em> included in M64MM3</li>
                        <li>Yoshielectron's old, but still functional <a href="https://sites.google.com/site/jamesskingdom/Home/my-programs-by-james-s">color code generator</a></li>
                        <li>the404's <a href="https://sm64code.the404.nl/">color code creator website</a> (warning: does not support shading)</li>
                        <li><s>comet<strong>spectrum</strong> own color code generator</s> Not done yet, check back later ;)</li>
                    </ul>
                    <h2 id="spark">1.2. What is SPARK?</h2>
                    <p>CometSPARK, or shorter: SPARK codes are for mods that support more colors than Mario normally supports, but through color codes, not textures.<br />
                    Imagine SPARK Mario as a X³ Mario mod, but you can use color codes instead of textures, and more than one mod can become a SPARK mod.<br /><br />
                    comet<strong>spectrum</strong> has SPARK support. For more info on CometSPARK, watch <a href="https://www.youtube.com/watch?v=DB9G3MJe5go">this video</a>.<br /><br />
                    You can only create SPARK Codes using M64MM3 with the SPARK Studio addon as of right now.<br /> <br />
                    </p>
                    <h1>2. How do I get somebody else's color code?</h1>
                    <h3 id="get-web">If using the Web interface:</h3>
                    <p>Go to <Link to="/" className="reference">LOOKUP</Link> and search by their Handle or, if they don't have one, their Discord username.<br /><br />
                    After doing this, click on their name, and a list of color codes will appear. From there you can copy a color code or share it elsewhere by clicking it and checking the options to your right (or below).
                    </p>
                    <br />
                    <h3 id="get-bot">If using the Discord Bot:</h3><br />
                    <p>Use the <code>cs!ccget</code> command like this: <code>cs!ccget &lt;name of person, Handle or ping&gt; &lt;slot number&gt;</code> (without brackets!) to get their color code at that slot.<br />If you only provide the name, you will get their default color code.<br />If you only provide the slot, you will get your own color code at that slot.<br />
                    To find out the color codes a person has, use the command <code>cs!cclist &lt;name of person, Handle or ping&gt;</code> to get the full list of their color codes. <br /><br />
                        <strong>Example:</strong>
                        <br />
                        <ul>
                            <li>
                                To get <em>my</em> default color code:<br />
                                <code>cs!ccget GlitchyPSI</code><br />
                            </li>
                            <li>
                                To get <em>my</em> Color Code #2:<br />
                                <code>cs!ccget GlitchyPSI 2</code><br />
                            </li>
                            <li>
                                To get <em>your</em> default color code:<br />
                                <code>cs!ccget</code><br />
                            </li>
                            <li>
                                To get <em>your</em> Color Code #2:<br />
                                <code>cs!ccget 2</code><br />
                            </li>
                            <li>
                                To get <em>my</em> Color Code List:<br />
                                <code>cs!cclist GlitchyPSI</code><br />
                            </li>
                            <li>
                                To get <em>your</em> Color Code List:<br />
                                <code>cs!cclist</code><br />
                            </li>
                        </ul>
                    </p>
                    <h1>3. How do I save a color code to the database?</h1>
                    <h3 id="save-web">If using the Web interface:</h3>
                    <p><strong>You can't, not yet</strong>. This is being worked on, but in the meantime, <Link to="/invite">invite</Link> the bot to your Discord server and have fun with it!<br /><br />
                    </p>
                    <h3 id="save-bot">If using the Discord Bot:</h3>
                    <p>Easy. Write <code>cs!ccsave</code>, then space, then paste your color code, then press Enter.<br /><br />
                    Note that comet<strong>spectrum</strong> only supports NTSC region color codes.
                    </p>
                    <h1 id="save-more">4. How do I save more than one color code?</h1>
                    <p>Also easy. Do the same you did to save a color code in the bot, but before pasting your color code, press space, add the slot number (starts at 1) and then paste your color code, then press Enter.<br />
                    </p>
                    <h1 id="slots">5. How do I know how many slots do I have?</h1>
                    <p>You can use the command <code>cs!memberstats</code> to see your support status and how many color code slots you have. You should have <strong>8</strong> as a regular user.<br /><br />
                    </p>
                    <h1 id="get-slots">6. How do I get more slots?</h1>
                    <p>You can:</p>
                    <ul>
                        <li>Join <a href="https://comet.glitchypsi.xyz">the Project Comet Discord</a> and use the cs!claim command (12 slots)
                        <li>become a Naz tier patron in <a href="https://patreon.com/GlitchyPSI">Patreon</a> (30 slots)</li>
                            <li>Win contests that are endorsed by Project Comet that have CC slots as prizes.</li>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default CMTSAbout;